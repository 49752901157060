import { render, staticRenderFns } from "./GeneralSpecialtyAdd.vue?vue&type=template&id=412c3a50&scoped=true&"
import script from "./GeneralSpecialtyAdd.vue?vue&type=script&lang=js&"
export * from "./GeneralSpecialtyAdd.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "412c3a50",
  null
  
)

export default component.exports