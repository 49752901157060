import { render, staticRenderFns } from "./BodyAnatomyTypeDelete.vue?vue&type=template&id=40861836&scoped=true&"
import script from "./BodyAnatomyTypeDelete.vue?vue&type=script&lang=js&"
export * from "./BodyAnatomyTypeDelete.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "40861836",
  null
  
)

export default component.exports