import { render, staticRenderFns } from "./ServicesReservationAdd.vue?vue&type=template&id=44164346&scoped=true&"
import script from "./ServicesReservationAdd.vue?vue&type=script&lang=js&"
export * from "./ServicesReservationAdd.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "44164346",
  null
  
)

export default component.exports