var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('table',{staticClass:"my-table"},[_c('tr',[_c('td',[_c('aplayer',{attrs:{"audio":_vm.audio}})],1),_c('td',[_c('router-link',{attrs:{"to":{
            name: 'ReservationMediaEdit',
            params: {
              clientToken: _vm.reservationMediaData.clientToken,
              reservationMediaToken:
                _vm.reservationMediaData.reservationMediaToken,
            },
          },"title":_vm.$t('edit')}},[_c('img',{staticClass:"icon-lg",attrs:{"src":require("@/assets/images/pencil.svg")}})])],1),_c('td',[_c('button',{directives:[{name:"b-modal",rawName:"v-b-modal.ReservationMediaDelete",modifiers:{"ReservationMediaDelete":true}}],staticClass:"btn p-0",attrs:{"title":_vm.$t('delete')},on:{"click":function($event){return _vm.setReservationMediaData(_vm.reservationMediaData)}}},[_c('img',{staticClass:"icon-lg",attrs:{"src":require("@/assets/images/trash.svg")}})])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }