import { render, staticRenderFns } from "./SurgeryAdd.vue?vue&type=template&id=3e7ed282&scoped=true&"
import script from "./SurgeryAdd.vue?vue&type=script&lang=js&"
export * from "./SurgeryAdd.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3e7ed282",
  null
  
)

export default component.exports