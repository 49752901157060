var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{},[_c('b-button',{directives:[{name:"b-toggle",rawName:"v-b-toggle.requirements",modifiers:{"requirements":true}}],staticClass:"btn btn-lg btn-collapse"},[_vm._v(" "+_vm._s(_vm.$t("historicalPlans.requirements.modelName"))+" "),_c('i',{staticClass:"fa fa-angle-down",attrs:{"aria-hidden":"true"}})]),_c('b-collapse',{attrs:{"id":"requirements"}},[_c('div',{staticClass:"my-card"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"form-label-group col-md-6 col-lg-4"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
              _vm.usersPrivilegeMedicalRequirementsPlan.medicalRequirementsPlanView
            ),expression:"\n              usersPrivilegeMedicalRequirementsPlan.medicalRequirementsPlanView\n            "}],staticClass:"checkbox",attrs:{"type":"checkbox","id":"medicalRequirementsPlanView"},domProps:{"checked":Array.isArray(
              _vm.usersPrivilegeMedicalRequirementsPlan.medicalRequirementsPlanView
            )?_vm._i(
              _vm.usersPrivilegeMedicalRequirementsPlan.medicalRequirementsPlanView
            ,null)>-1:(
              _vm.usersPrivilegeMedicalRequirementsPlan.medicalRequirementsPlanView
            )},on:{"change":function($event){var $$a=
              _vm.usersPrivilegeMedicalRequirementsPlan.medicalRequirementsPlanView
            ,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.usersPrivilegeMedicalRequirementsPlan, "medicalRequirementsPlanView", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.usersPrivilegeMedicalRequirementsPlan, "medicalRequirementsPlanView", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.usersPrivilegeMedicalRequirementsPlan, "medicalRequirementsPlanView", $$c)}}}}),_c('label',{staticClass:"mt-1 ml-2",attrs:{"for":"medicalRequirementsPlanView"}},[_vm._v(_vm._s(_vm.$t("view")))])]),_c('div',{staticClass:"form-label-group col-md-6 col-lg-4"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
              _vm.usersPrivilegeMedicalRequirementsPlan.medicalRequirementsPlanAdd
            ),expression:"\n              usersPrivilegeMedicalRequirementsPlan.medicalRequirementsPlanAdd\n            "}],staticClass:"checkbox",attrs:{"type":"checkbox","id":"medicalRequirementsPlanAdd"},domProps:{"checked":Array.isArray(
              _vm.usersPrivilegeMedicalRequirementsPlan.medicalRequirementsPlanAdd
            )?_vm._i(
              _vm.usersPrivilegeMedicalRequirementsPlan.medicalRequirementsPlanAdd
            ,null)>-1:(
              _vm.usersPrivilegeMedicalRequirementsPlan.medicalRequirementsPlanAdd
            )},on:{"change":function($event){var $$a=
              _vm.usersPrivilegeMedicalRequirementsPlan.medicalRequirementsPlanAdd
            ,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.usersPrivilegeMedicalRequirementsPlan, "medicalRequirementsPlanAdd", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.usersPrivilegeMedicalRequirementsPlan, "medicalRequirementsPlanAdd", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.usersPrivilegeMedicalRequirementsPlan, "medicalRequirementsPlanAdd", $$c)}}}}),_c('label',{staticClass:"mt-1 ml-2",attrs:{"for":"medicalRequirementsPlanAdd"}},[_vm._v(_vm._s(_vm.$t("add")))])]),_c('div',{staticClass:"form-label-group col-md-6 col-lg-4"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
              _vm.usersPrivilegeMedicalRequirementsPlan.medicalRequirementsPlanUpdate
            ),expression:"\n              usersPrivilegeMedicalRequirementsPlan.medicalRequirementsPlanUpdate\n            "}],staticClass:"checkbox",attrs:{"type":"checkbox","id":"medicalRequirementsPlanUpdate"},domProps:{"checked":Array.isArray(
              _vm.usersPrivilegeMedicalRequirementsPlan.medicalRequirementsPlanUpdate
            )?_vm._i(
              _vm.usersPrivilegeMedicalRequirementsPlan.medicalRequirementsPlanUpdate
            ,null)>-1:(
              _vm.usersPrivilegeMedicalRequirementsPlan.medicalRequirementsPlanUpdate
            )},on:{"change":function($event){var $$a=
              _vm.usersPrivilegeMedicalRequirementsPlan.medicalRequirementsPlanUpdate
            ,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.usersPrivilegeMedicalRequirementsPlan, "medicalRequirementsPlanUpdate", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.usersPrivilegeMedicalRequirementsPlan, "medicalRequirementsPlanUpdate", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.usersPrivilegeMedicalRequirementsPlan, "medicalRequirementsPlanUpdate", $$c)}}}}),_c('label',{staticClass:"mt-1 ml-2",attrs:{"for":"medicalRequirementsPlanUpdate"}},[_vm._v(_vm._s(_vm.$t("edit")))])]),_c('div',{staticClass:"form-label-group col-md-6 col-lg-4"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
              _vm.usersPrivilegeMedicalRequirementsPlan.medicalRequirementsPlanArchive
            ),expression:"\n              usersPrivilegeMedicalRequirementsPlan.medicalRequirementsPlanArchive\n            "}],staticClass:"checkbox",attrs:{"type":"checkbox","id":"medicalRequirementsPlanArchive"},domProps:{"checked":Array.isArray(
              _vm.usersPrivilegeMedicalRequirementsPlan.medicalRequirementsPlanArchive
            )?_vm._i(
              _vm.usersPrivilegeMedicalRequirementsPlan.medicalRequirementsPlanArchive
            ,null)>-1:(
              _vm.usersPrivilegeMedicalRequirementsPlan.medicalRequirementsPlanArchive
            )},on:{"change":function($event){var $$a=
              _vm.usersPrivilegeMedicalRequirementsPlan.medicalRequirementsPlanArchive
            ,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.usersPrivilegeMedicalRequirementsPlan, "medicalRequirementsPlanArchive", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.usersPrivilegeMedicalRequirementsPlan, "medicalRequirementsPlanArchive", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.usersPrivilegeMedicalRequirementsPlan, "medicalRequirementsPlanArchive", $$c)}}}}),_c('label',{staticClass:"mt-1 ml-2",attrs:{"for":"medicalRequirementsPlanArchive"}},[_vm._v(_vm._s(_vm.$t("archive")))])]),_c('div',{staticClass:"form-label-group col-md-6 col-lg-4"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
              _vm.usersPrivilegeMedicalRequirementsPlan.medicalRequirementsPlanDelete
            ),expression:"\n              usersPrivilegeMedicalRequirementsPlan.medicalRequirementsPlanDelete\n            "}],staticClass:"checkbox",attrs:{"type":"checkbox","id":"medicalRequirementsPlanDelete"},domProps:{"checked":Array.isArray(
              _vm.usersPrivilegeMedicalRequirementsPlan.medicalRequirementsPlanDelete
            )?_vm._i(
              _vm.usersPrivilegeMedicalRequirementsPlan.medicalRequirementsPlanDelete
            ,null)>-1:(
              _vm.usersPrivilegeMedicalRequirementsPlan.medicalRequirementsPlanDelete
            )},on:{"change":function($event){var $$a=
              _vm.usersPrivilegeMedicalRequirementsPlan.medicalRequirementsPlanDelete
            ,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.usersPrivilegeMedicalRequirementsPlan, "medicalRequirementsPlanDelete", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.usersPrivilegeMedicalRequirementsPlan, "medicalRequirementsPlanDelete", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.usersPrivilegeMedicalRequirementsPlan, "medicalRequirementsPlanDelete", $$c)}}}}),_c('label',{staticClass:"mt-1 ml-2",attrs:{"for":"medicalRequirementsPlanDelete"}},[_vm._v(_vm._s(_vm.$t("finalDelete")))])]),_c('div',{staticClass:"form-label-group col-md-6 col-lg-4"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
              _vm.usersPrivilegeMedicalRequirementsPlan.medicalRequirementsPlanRestore
            ),expression:"\n              usersPrivilegeMedicalRequirementsPlan.medicalRequirementsPlanRestore\n            "}],staticClass:"checkbox",attrs:{"type":"checkbox","id":"medicalRequirementsPlanRestore"},domProps:{"checked":Array.isArray(
              _vm.usersPrivilegeMedicalRequirementsPlan.medicalRequirementsPlanRestore
            )?_vm._i(
              _vm.usersPrivilegeMedicalRequirementsPlan.medicalRequirementsPlanRestore
            ,null)>-1:(
              _vm.usersPrivilegeMedicalRequirementsPlan.medicalRequirementsPlanRestore
            )},on:{"change":function($event){var $$a=
              _vm.usersPrivilegeMedicalRequirementsPlan.medicalRequirementsPlanRestore
            ,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.usersPrivilegeMedicalRequirementsPlan, "medicalRequirementsPlanRestore", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.usersPrivilegeMedicalRequirementsPlan, "medicalRequirementsPlanRestore", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.usersPrivilegeMedicalRequirementsPlan, "medicalRequirementsPlanRestore", $$c)}}}}),_c('label',{staticClass:"mt-1 ml-2",attrs:{"for":"medicalRequirementsPlanRestore"}},[_vm._v(_vm._s(_vm.$t("restore")))])])])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }