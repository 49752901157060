import { render, staticRenderFns } from "./SpecialSpecialtyAdd.vue?vue&type=template&id=e3e2061c&scoped=true&"
import script from "./SpecialSpecialtyAdd.vue?vue&type=script&lang=js&"
export * from "./SpecialSpecialtyAdd.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e3e2061c",
  null
  
)

export default component.exports