var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{},[_c('form',{attrs:{"autocomplete":"off"}},[_c('div',{staticClass:"my-card"},[_c('div',{staticClass:"row"},[(_vm.employeeTypeToken != _vm.employeeType.Doctor)?_c('CustomSelectBox',{attrs:{"className":'col-md-12',"id":'employeeToken',"value":_vm.addictiveStuffClientData.employeeTokenInReport,"options":_vm.employeeTokenOptions,"title":_vm.$t('selectEmployeeName'),"imgName":'doctors.svg'},on:{"changeValue":function($event){_vm.addictiveStuffClientData.employeeTokenInReport = $event}}}):_vm._e(),_c('CustomSelectBox',{attrs:{"className":'col-md-12',"id":'clientToken',"value":_vm.addictiveStuffClientData.clientToken,"options":_vm.clientTokenOptions,"title":_vm.$t('selectClient'),"imgName":'clients.svg'},on:{"changeValue":function($event){_vm.addictiveStuffClientData.clientToken = $event}}})],1)]),_c('div',{staticClass:"my-card"},[_c('div',{staticClass:"row"},[_c('CustomSelectBox',{attrs:{"className":'col-md-12',"id":'addictiveStuffToken',"value":_vm.addictiveStuffClientData.addictiveStuffToken,"options":_vm.addictiveStuffTokenOptions,"title":_vm.select,"imgName":_vm.image},on:{"changeValue":function($event){_vm.addictiveStuffClientData.addictiveStuffToken = $event}}}),_c('TextArea',{attrs:{"className":'col-md-12',"id":"addictiveStuffClientNotes","value":_vm.addictiveStuffClientData.addictiveStuffClientNotes,"title":_vm.note,"imgName":'notes.svg'},on:{"changeValue":function($event){_vm.addictiveStuffClientData.addictiveStuffClientNotes = $event}}}),_vm._v(" "),_c('DatePicker',{attrs:{"className":'col-md-12',"id":'injuryDate',"value":_vm.addictiveStuffClientData.injuryDate,"title":_vm.isHabit
              ? _vm.$t('addictiveStuffs.injuryHabit.date')
              : _vm.$t('addictiveStuffs.injury.date'),"language":_vm.language},on:{"changeValue":function($event){_vm.addictiveStuffClientData.injuryDate = $event}}}),_c('TextArea',{attrs:{"className":'col-md-6',"id":"InjuryReasonAr","value":_vm.addictiveStuffClientData.InjuryReasonAr,"title":_vm.isHabit
              ? _vm.$t('addictiveStuffs.injuryHabit.reasonAr')
              : _vm.$t('addictiveStuffs.injury.reasonAr'),"imgName":'reason.svg'},on:{"changeValue":function($event){_vm.addictiveStuffClientData.InjuryReasonAr = $event}}}),_vm._v(" "),_c('TextArea',{attrs:{"className":'col-md-6',"id":"InjuryReasonEn","value":_vm.addictiveStuffClientData.InjuryReasonEn,"title":_vm.isHabit
              ? _vm.$t('addictiveStuffs.injuryHabit.reasonEn')
              : _vm.$t('addictiveStuffs.injury.reasonEn'),"imgName":'reason.svg'},on:{"changeValue":function($event){_vm.addictiveStuffClientData.InjuryReasonEn = $event}}}),_vm._v(" "),_c('TextArea',{attrs:{"className":'col-md-6',"id":"InjuryReasonUnd","value":_vm.addictiveStuffClientData.InjuryReasonUnd,"title":_vm.isHabit
              ? _vm.$t('addictiveStuffs.injuryHabit.reasonUnd')
              : _vm.$t('addictiveStuffs.injury.reasonUnd'),"imgName":'reason.svg'},on:{"changeValue":function($event){_vm.addictiveStuffClientData.InjuryReasonUnd = $event}}}),_c('TextArea',{attrs:{"className":'col-md-6',"id":"InjurySyndromeAr","value":_vm.addictiveStuffClientData.InjurySyndromeAr,"title":_vm.isHabit
              ? _vm.$t('addictiveStuffs.injuryHabit.syndromeAr')
              : _vm.$t('addictiveStuffs.injury.syndromeAr'),"imgName":'syndrome.svg'},on:{"changeValue":function($event){_vm.addictiveStuffClientData.InjurySyndromeAr = $event}}}),_vm._v(" "),_c('TextArea',{attrs:{"className":'col-md-6',"id":"InjurySyndromeEn","value":_vm.addictiveStuffClientData.InjurySyndromeEn,"title":_vm.isHabit
              ? _vm.$t('addictiveStuffs.injuryHabit.syndromeEn')
              : _vm.$t('addictiveStuffs.injury.syndromeEn'),"imgName":'syndrome.svg'},on:{"changeValue":function($event){_vm.addictiveStuffClientData.InjurySyndromeEn = $event}}}),_vm._v(" "),_c('TextArea',{attrs:{"className":'col-md-6',"id":"InjurySyndromeUnd","value":_vm.addictiveStuffClientData.InjurySyndromeUnd,"title":_vm.isHabit
              ? _vm.$t('addictiveStuffs.injuryHabit.syndromeUnd')
              : _vm.$t('addictiveStuffs.injury.syndromeUnd'),"imgName":'syndrome.svg'},on:{"changeValue":function($event){_vm.addictiveStuffClientData.InjurySyndromeUnd = $event}}}),_c('TextArea',{attrs:{"className":'col-md-6',"id":"InjuryDetailsAr","value":_vm.addictiveStuffClientData.InjuryDetailsAr,"title":_vm.isHabit
              ? _vm.$t('addictiveStuffs.injuryHabit.detailsAr')
              : _vm.$t('addictiveStuffs.injury.detailsAr'),"imgName":'details.svg'},on:{"changeValue":function($event){_vm.addictiveStuffClientData.InjuryDetailsAr = $event}}}),_vm._v(" "),_c('TextArea',{attrs:{"className":'col-md-6',"id":"InjuryDetailsEn","value":_vm.addictiveStuffClientData.InjuryDetailsEn,"title":_vm.isHabit
              ? _vm.$t('addictiveStuffs.injuryHabit.detailsEn')
              : _vm.$t('addictiveStuffs.injury.detailsEn'),"imgName":'details.svg'},on:{"changeValue":function($event){_vm.addictiveStuffClientData.InjuryDetailsEn = $event}}}),_vm._v(" "),_c('TextArea',{attrs:{"className":'col-md-6',"id":"InjuryDetailsUnd","value":_vm.addictiveStuffClientData.InjuryDetailsUnd,"title":_vm.isHabit
              ? _vm.$t('addictiveStuffs.injuryHabit.detailsUnd')
              : _vm.$t('addictiveStuffs.injury.detailsUnd'),"imgName":'details.svg'},on:{"changeValue":function($event){_vm.addictiveStuffClientData.InjuryDetailsUnd = $event}}}),_c('TextArea',{attrs:{"className":'col-md-6',"id":"InjuryTreatmentAr","value":_vm.addictiveStuffClientData.InjuryTreatmentAr,"title":_vm.isHabit
              ? _vm.$t('addictiveStuffs.injuryHabit.treatmentAr')
              : _vm.$t('addictiveStuffs.injury.treatmentAr'),"imgName":'treatments.svg'},on:{"changeValue":function($event){_vm.addictiveStuffClientData.InjuryTreatmentAr = $event}}}),_vm._v(" "),_c('TextArea',{attrs:{"className":'col-md-6',"id":"InjuryTreatmentEn","value":_vm.addictiveStuffClientData.InjuryTreatmentEn,"title":_vm.isHabit
              ? _vm.$t('addictiveStuffs.injuryHabit.treatmentEn')
              : _vm.$t('addictiveStuffs.injury.treatmentEn'),"imgName":'treatments.svg'},on:{"changeValue":function($event){_vm.addictiveStuffClientData.InjuryTreatmentEn = $event}}}),_vm._v(" "),_c('TextArea',{attrs:{"className":'col-md-6',"id":"InjuryTreatmentUnd","value":_vm.addictiveStuffClientData.InjuryTreatmentUnd,"title":_vm.isHabit
              ? _vm.$t('addictiveStuffs.injuryHabit.treatmentUnd')
              : _vm.$t('addictiveStuffs.injury.treatmentUnd'),"imgName":'treatments.svg'},on:{"changeValue":function($event){_vm.addictiveStuffClientData.InjuryTreatmentUnd = $event}}}),_vm._v(" "),_c('CustomCheckbox',{attrs:{"className":'col-md-12',"value":_vm.addictiveStuffClientData.recoveryState,"title":_vm.$t('addictiveStuffs.recoveried')},on:{"changeValue":function($event){_vm.addictiveStuffClientData.recoveryState = $event}}}),_c('DatePicker',{directives:[{name:"show",rawName:"v-show",value:(_vm.addictiveStuffClientData.recoveryState),expression:"addictiveStuffClientData.recoveryState"}],attrs:{"className":'col-md-6',"id":'recoveryDate',"value":_vm.addictiveStuffClientData.recoveryDate,"title":_vm.$t('addictiveStuffs.recoveryDate'),"imgName":'habits.svg',"language":_vm.language},on:{"changeValue":function($event){_vm.addictiveStuffClientData.recoveryDate = $event}}}),_c('TextArea',{directives:[{name:"show",rawName:"v-show",value:(_vm.addictiveStuffClientData.recoveryState),expression:"addictiveStuffClientData.recoveryState"}],attrs:{"className":'col-md-6',"id":"recoveryNotes","value":_vm.addictiveStuffClientData.recoveryNotes,"title":_vm.$t('addictiveStuffs.recoveryNotes'),"imgName":'notes.svg'},on:{"changeValue":function($event){_vm.addictiveStuffClientData.recoveryNotes = $event}}})],1)]),_c('div',{staticClass:"form-footer"},[_c('button',{staticClass:"btn btn-submit",attrs:{"name":"submit","type":"submit"},on:{"click":function($event){$event.preventDefault();return _vm.addOrUpdateAddictiveStuffClient.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.submitName)+" ")]),(
          _vm.addictiveStuffClientData.addictiveStuffTypeToken ==
          _vm.addictiveStuffTypeToken.Habits
        )?_c('router-link',{staticClass:"btn btn-cancel",attrs:{"to":{
          name: 'AddictiveStuffClientsHabits',
          params: {
            clientToken: _vm.addictiveStuffClientData.clientToken,
          },
        }}},[_vm._v(" "+_vm._s(_vm.$t("cancel"))+" ")]):(
          _vm.addictiveStuffClientData.addictiveStuffTypeToken ==
          _vm.addictiveStuffTypeToken.Diseases
        )?_c('router-link',{staticClass:"btn btn-cancel",attrs:{"to":{
          name: 'AddictiveStuffClientsDiseases',
          params: {
            clientToken: _vm.addictiveStuffClientData.clientToken,
          },
        }}},[_vm._v(" "+_vm._s(_vm.$t("cancel"))+" ")]):_vm._e()],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }