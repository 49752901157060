var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{},[_c('b-button',{directives:[{name:"b-toggle",rawName:"v-b-toggle.servicesReservations",modifiers:{"servicesReservations":true}}],staticClass:"btn btn-lg btn-collapse"},[_vm._v(" "+_vm._s(_vm.$t("servicesReservations"))+" "),_c('i',{staticClass:"fa fa-angle-down",attrs:{"aria-hidden":"true"}})]),_c('b-collapse',{attrs:{"id":"servicesReservations"}},[_c('div',{staticClass:"my-card"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"form-label-group col-md-6 col-lg-4"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
              _vm.usersPrivilegeServicesReservations.servicesReservationView
            ),expression:"\n              usersPrivilegeServicesReservations.servicesReservationView\n            "}],staticClass:"checkbox",attrs:{"type":"checkbox","id":"servicesReservationView"},domProps:{"checked":Array.isArray(
              _vm.usersPrivilegeServicesReservations.servicesReservationView
            )?_vm._i(
              _vm.usersPrivilegeServicesReservations.servicesReservationView
            ,null)>-1:(
              _vm.usersPrivilegeServicesReservations.servicesReservationView
            )},on:{"change":function($event){var $$a=
              _vm.usersPrivilegeServicesReservations.servicesReservationView
            ,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.usersPrivilegeServicesReservations, "servicesReservationView", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.usersPrivilegeServicesReservations, "servicesReservationView", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.usersPrivilegeServicesReservations, "servicesReservationView", $$c)}}}}),_c('label',{staticClass:"mt-1 ml-2",attrs:{"for":"servicesReservationView"}},[_vm._v(_vm._s(_vm.$t("view")))])]),_c('div',{staticClass:"form-label-group col-md-6 col-lg-4"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
              _vm.usersPrivilegeServicesReservations.servicesReservationAdd
            ),expression:"\n              usersPrivilegeServicesReservations.servicesReservationAdd\n            "}],staticClass:"checkbox",attrs:{"type":"checkbox","id":"servicesReservationAdd"},domProps:{"checked":Array.isArray(
              _vm.usersPrivilegeServicesReservations.servicesReservationAdd
            )?_vm._i(
              _vm.usersPrivilegeServicesReservations.servicesReservationAdd
            ,null)>-1:(
              _vm.usersPrivilegeServicesReservations.servicesReservationAdd
            )},on:{"change":function($event){var $$a=
              _vm.usersPrivilegeServicesReservations.servicesReservationAdd
            ,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.usersPrivilegeServicesReservations, "servicesReservationAdd", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.usersPrivilegeServicesReservations, "servicesReservationAdd", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.usersPrivilegeServicesReservations, "servicesReservationAdd", $$c)}}}}),_c('label',{staticClass:"mt-1 ml-2",attrs:{"for":"servicesReservationAdd"}},[_vm._v(_vm._s(_vm.$t("add")))])]),_c('div',{staticClass:"form-label-group col-md-6 col-lg-4"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
              _vm.usersPrivilegeServicesReservations.servicesReservationApprov
            ),expression:"\n              usersPrivilegeServicesReservations.servicesReservationApprov\n            "}],staticClass:"checkbox",attrs:{"type":"checkbox","id":"servicesReservationApprov"},domProps:{"checked":Array.isArray(
              _vm.usersPrivilegeServicesReservations.servicesReservationApprov
            )?_vm._i(
              _vm.usersPrivilegeServicesReservations.servicesReservationApprov
            ,null)>-1:(
              _vm.usersPrivilegeServicesReservations.servicesReservationApprov
            )},on:{"change":function($event){var $$a=
              _vm.usersPrivilegeServicesReservations.servicesReservationApprov
            ,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.usersPrivilegeServicesReservations, "servicesReservationApprov", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.usersPrivilegeServicesReservations, "servicesReservationApprov", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.usersPrivilegeServicesReservations, "servicesReservationApprov", $$c)}}}}),_c('label',{staticClass:"mt-1 ml-2",attrs:{"for":"servicesReservationApprov"}},[_vm._v(_vm._s(_vm.$t("approveOnServicesReservation")))])]),_c('div',{staticClass:"form-label-group col-md-6 col-lg-4"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
              _vm.usersPrivilegeServicesReservations.servicesReservationAddCancel
            ),expression:"\n              usersPrivilegeServicesReservations.servicesReservationAddCancel\n            "}],staticClass:"checkbox",attrs:{"type":"checkbox","id":"servicesReservationAddCancel"},domProps:{"checked":Array.isArray(
              _vm.usersPrivilegeServicesReservations.servicesReservationAddCancel
            )?_vm._i(
              _vm.usersPrivilegeServicesReservations.servicesReservationAddCancel
            ,null)>-1:(
              _vm.usersPrivilegeServicesReservations.servicesReservationAddCancel
            )},on:{"change":function($event){var $$a=
              _vm.usersPrivilegeServicesReservations.servicesReservationAddCancel
            ,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.usersPrivilegeServicesReservations, "servicesReservationAddCancel", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.usersPrivilegeServicesReservations, "servicesReservationAddCancel", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.usersPrivilegeServicesReservations, "servicesReservationAddCancel", $$c)}}}}),_c('label',{staticClass:"mt-1 ml-2",attrs:{"for":"servicesReservationAddCancel"}},[_vm._v(_vm._s(_vm.$t("cancelServicesReservation")))])]),_c('div',{staticClass:"form-label-group col-md-6 col-lg-4"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
              _vm.usersPrivilegeServicesReservations.servicesReservationAttendance
            ),expression:"\n              usersPrivilegeServicesReservations.servicesReservationAttendance\n            "}],staticClass:"checkbox",attrs:{"type":"checkbox","id":"servicesReservationAttendance"},domProps:{"checked":Array.isArray(
              _vm.usersPrivilegeServicesReservations.servicesReservationAttendance
            )?_vm._i(
              _vm.usersPrivilegeServicesReservations.servicesReservationAttendance
            ,null)>-1:(
              _vm.usersPrivilegeServicesReservations.servicesReservationAttendance
            )},on:{"change":function($event){var $$a=
              _vm.usersPrivilegeServicesReservations.servicesReservationAttendance
            ,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.usersPrivilegeServicesReservations, "servicesReservationAttendance", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.usersPrivilegeServicesReservations, "servicesReservationAttendance", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.usersPrivilegeServicesReservations, "servicesReservationAttendance", $$c)}}}}),_c('label',{staticClass:"mt-1 ml-2",attrs:{"for":"servicesReservationAttendance"}},[_vm._v(_vm._s(_vm.$t("attendServicesReservation")))])]),_c('div',{staticClass:"form-label-group col-md-6 col-lg-4"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
              _vm.usersPrivilegeServicesReservations.servicesReservationEnter
            ),expression:"\n              usersPrivilegeServicesReservations.servicesReservationEnter\n            "}],staticClass:"checkbox",attrs:{"type":"checkbox","id":"servicesReservationEnter"},domProps:{"checked":Array.isArray(
              _vm.usersPrivilegeServicesReservations.servicesReservationEnter
            )?_vm._i(
              _vm.usersPrivilegeServicesReservations.servicesReservationEnter
            ,null)>-1:(
              _vm.usersPrivilegeServicesReservations.servicesReservationEnter
            )},on:{"change":function($event){var $$a=
              _vm.usersPrivilegeServicesReservations.servicesReservationEnter
            ,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.usersPrivilegeServicesReservations, "servicesReservationEnter", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.usersPrivilegeServicesReservations, "servicesReservationEnter", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.usersPrivilegeServicesReservations, "servicesReservationEnter", $$c)}}}}),_c('label',{staticClass:"mt-1 ml-2",attrs:{"for":"servicesReservationEnter"}},[_vm._v(_vm._s(_vm.$t("enterServicesReservation")))])]),_c('div',{staticClass:"form-label-group col-md-6 col-lg-4"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
              _vm.usersPrivilegeServicesReservations.servicesReservationWaitAndWillComeBack
            ),expression:"\n              usersPrivilegeServicesReservations.servicesReservationWaitAndWillComeBack\n            "}],staticClass:"checkbox",attrs:{"type":"checkbox","id":"servicesReservationWaitAndWillComeBack"},domProps:{"checked":Array.isArray(
              _vm.usersPrivilegeServicesReservations.servicesReservationWaitAndWillComeBack
            )?_vm._i(
              _vm.usersPrivilegeServicesReservations.servicesReservationWaitAndWillComeBack
            ,null)>-1:(
              _vm.usersPrivilegeServicesReservations.servicesReservationWaitAndWillComeBack
            )},on:{"change":function($event){var $$a=
              _vm.usersPrivilegeServicesReservations.servicesReservationWaitAndWillComeBack
            ,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.usersPrivilegeServicesReservations, "servicesReservationWaitAndWillComeBack", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.usersPrivilegeServicesReservations, "servicesReservationWaitAndWillComeBack", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.usersPrivilegeServicesReservations, "servicesReservationWaitAndWillComeBack", $$c)}}}}),_c('label',{staticClass:"mt-1 ml-2",attrs:{"for":"servicesReservationWaitAndWillComeBack"}},[_vm._v(_vm._s(_vm.$t("waitForComeBackServicesReservation")))])]),_c('div',{staticClass:"form-label-group col-md-6 col-lg-4"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
              _vm.usersPrivilegeServicesReservations.servicesReservationFinish
            ),expression:"\n              usersPrivilegeServicesReservations.servicesReservationFinish\n            "}],staticClass:"checkbox",attrs:{"type":"checkbox","id":"servicesReservationFinish"},domProps:{"checked":Array.isArray(
              _vm.usersPrivilegeServicesReservations.servicesReservationFinish
            )?_vm._i(
              _vm.usersPrivilegeServicesReservations.servicesReservationFinish
            ,null)>-1:(
              _vm.usersPrivilegeServicesReservations.servicesReservationFinish
            )},on:{"change":function($event){var $$a=
              _vm.usersPrivilegeServicesReservations.servicesReservationFinish
            ,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.usersPrivilegeServicesReservations, "servicesReservationFinish", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.usersPrivilegeServicesReservations, "servicesReservationFinish", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.usersPrivilegeServicesReservations, "servicesReservationFinish", $$c)}}}}),_c('label',{staticClass:"mt-1 ml-2",attrs:{"for":"servicesReservationFinish"}},[_vm._v(_vm._s(_vm.$t("finishServicesReservation")))])]),_c('div',{staticClass:"form-label-group col-md-6 col-lg-4"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
              _vm.usersPrivilegeServicesReservations.servicesReservationViewPay
            ),expression:"\n              usersPrivilegeServicesReservations.servicesReservationViewPay\n            "}],staticClass:"checkbox",attrs:{"type":"checkbox","id":"servicesReservationViewPay"},domProps:{"checked":Array.isArray(
              _vm.usersPrivilegeServicesReservations.servicesReservationViewPay
            )?_vm._i(
              _vm.usersPrivilegeServicesReservations.servicesReservationViewPay
            ,null)>-1:(
              _vm.usersPrivilegeServicesReservations.servicesReservationViewPay
            )},on:{"change":function($event){var $$a=
              _vm.usersPrivilegeServicesReservations.servicesReservationViewPay
            ,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.usersPrivilegeServicesReservations, "servicesReservationViewPay", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.usersPrivilegeServicesReservations, "servicesReservationViewPay", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.usersPrivilegeServicesReservations, "servicesReservationViewPay", $$c)}}}}),_c('label',{staticClass:"mt-1 ml-2",attrs:{"for":"servicesReservationViewPay"}},[_vm._v(_vm._s(_vm.$t("servicesPaymentsView")))])]),_c('div',{staticClass:"form-label-group col-md-6 col-lg-4"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
              _vm.usersPrivilegeServicesReservations.servicesReservationAddPay
            ),expression:"\n              usersPrivilegeServicesReservations.servicesReservationAddPay\n            "}],staticClass:"checkbox",attrs:{"type":"checkbox","id":"servicesReservationAddPay"},domProps:{"checked":Array.isArray(
              _vm.usersPrivilegeServicesReservations.servicesReservationAddPay
            )?_vm._i(
              _vm.usersPrivilegeServicesReservations.servicesReservationAddPay
            ,null)>-1:(
              _vm.usersPrivilegeServicesReservations.servicesReservationAddPay
            )},on:{"change":function($event){var $$a=
              _vm.usersPrivilegeServicesReservations.servicesReservationAddPay
            ,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.usersPrivilegeServicesReservations, "servicesReservationAddPay", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.usersPrivilegeServicesReservations, "servicesReservationAddPay", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.usersPrivilegeServicesReservations, "servicesReservationAddPay", $$c)}}}}),_c('label',{staticClass:"mt-1 ml-2",attrs:{"for":"servicesReservationAddPay"}},[_vm._v(_vm._s(_vm.$t("servicesPaymentsAdd")))])]),_c('div',{staticClass:"form-label-group col-md-6 col-lg-4"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
              _vm.usersPrivilegeServicesReservations.servicesReservationRefoundPay
            ),expression:"\n              usersPrivilegeServicesReservations.servicesReservationRefoundPay\n            "}],staticClass:"checkbox",attrs:{"type":"checkbox","id":"servicesReservationRefoundPay"},domProps:{"checked":Array.isArray(
              _vm.usersPrivilegeServicesReservations.servicesReservationRefoundPay
            )?_vm._i(
              _vm.usersPrivilegeServicesReservations.servicesReservationRefoundPay
            ,null)>-1:(
              _vm.usersPrivilegeServicesReservations.servicesReservationRefoundPay
            )},on:{"change":function($event){var $$a=
              _vm.usersPrivilegeServicesReservations.servicesReservationRefoundPay
            ,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.usersPrivilegeServicesReservations, "servicesReservationRefoundPay", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.usersPrivilegeServicesReservations, "servicesReservationRefoundPay", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.usersPrivilegeServicesReservations, "servicesReservationRefoundPay", $$c)}}}}),_c('label',{staticClass:"mt-1 ml-2",attrs:{"for":"servicesReservationRefoundPay"}},[_vm._v(_vm._s(_vm.$t("servicesPaymentsRefund")))])])])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }