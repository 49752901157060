var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{},[_c('b-button',{directives:[{name:"b-toggle",rawName:"v-b-toggle.diseasesAndHabits",modifiers:{"diseasesAndHabits":true}}],staticClass:"btn btn-lg btn-collapse"},[_vm._v(" "+_vm._s(_vm.$t("historicalPlans.diseasesAndHabits.modelName"))+" "),_c('i',{staticClass:"fa fa-angle-down",attrs:{"aria-hidden":"true"}})]),_c('b-collapse',{attrs:{"id":"diseasesAndHabits"}},[_c('div',{staticClass:"my-card"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"form-label-group col-md-6 col-lg-4"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
              _vm.usersPrivilegeAddictiveStuffClient.addictiveStuffClientView
            ),expression:"\n              usersPrivilegeAddictiveStuffClient.addictiveStuffClientView\n            "}],staticClass:"checkbox",attrs:{"type":"checkbox","id":"addictiveStuffClientView"},domProps:{"checked":Array.isArray(
              _vm.usersPrivilegeAddictiveStuffClient.addictiveStuffClientView
            )?_vm._i(
              _vm.usersPrivilegeAddictiveStuffClient.addictiveStuffClientView
            ,null)>-1:(
              _vm.usersPrivilegeAddictiveStuffClient.addictiveStuffClientView
            )},on:{"change":function($event){var $$a=
              _vm.usersPrivilegeAddictiveStuffClient.addictiveStuffClientView
            ,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.usersPrivilegeAddictiveStuffClient, "addictiveStuffClientView", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.usersPrivilegeAddictiveStuffClient, "addictiveStuffClientView", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.usersPrivilegeAddictiveStuffClient, "addictiveStuffClientView", $$c)}}}}),_c('label',{staticClass:"mt-1 ml-2",attrs:{"for":"addictiveStuffClientView"}},[_vm._v(_vm._s(_vm.$t("view")))])]),_c('div',{staticClass:"form-label-group col-md-6 col-lg-4"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
              _vm.usersPrivilegeAddictiveStuffClient.addictiveStuffClientAdd
            ),expression:"\n              usersPrivilegeAddictiveStuffClient.addictiveStuffClientAdd\n            "}],staticClass:"checkbox",attrs:{"type":"checkbox","id":"addictiveStuffClientAdd"},domProps:{"checked":Array.isArray(
              _vm.usersPrivilegeAddictiveStuffClient.addictiveStuffClientAdd
            )?_vm._i(
              _vm.usersPrivilegeAddictiveStuffClient.addictiveStuffClientAdd
            ,null)>-1:(
              _vm.usersPrivilegeAddictiveStuffClient.addictiveStuffClientAdd
            )},on:{"change":function($event){var $$a=
              _vm.usersPrivilegeAddictiveStuffClient.addictiveStuffClientAdd
            ,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.usersPrivilegeAddictiveStuffClient, "addictiveStuffClientAdd", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.usersPrivilegeAddictiveStuffClient, "addictiveStuffClientAdd", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.usersPrivilegeAddictiveStuffClient, "addictiveStuffClientAdd", $$c)}}}}),_c('label',{staticClass:"mt-1 ml-2",attrs:{"for":"addictiveStuffClientAdd"}},[_vm._v(_vm._s(_vm.$t("add")))])]),_c('div',{staticClass:"form-label-group col-md-6 col-lg-4"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
              _vm.usersPrivilegeAddictiveStuffClient.addictiveStuffClientUpdate
            ),expression:"\n              usersPrivilegeAddictiveStuffClient.addictiveStuffClientUpdate\n            "}],staticClass:"checkbox",attrs:{"type":"checkbox","id":"addictiveStuffClientUpdate"},domProps:{"checked":Array.isArray(
              _vm.usersPrivilegeAddictiveStuffClient.addictiveStuffClientUpdate
            )?_vm._i(
              _vm.usersPrivilegeAddictiveStuffClient.addictiveStuffClientUpdate
            ,null)>-1:(
              _vm.usersPrivilegeAddictiveStuffClient.addictiveStuffClientUpdate
            )},on:{"change":function($event){var $$a=
              _vm.usersPrivilegeAddictiveStuffClient.addictiveStuffClientUpdate
            ,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.usersPrivilegeAddictiveStuffClient, "addictiveStuffClientUpdate", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.usersPrivilegeAddictiveStuffClient, "addictiveStuffClientUpdate", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.usersPrivilegeAddictiveStuffClient, "addictiveStuffClientUpdate", $$c)}}}}),_c('label',{staticClass:"mt-1 ml-2",attrs:{"for":"addictiveStuffClientUpdate"}},[_vm._v(_vm._s(_vm.$t("edit")))])]),_c('div',{staticClass:"form-label-group col-md-6 col-lg-4"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
              _vm.usersPrivilegeAddictiveStuffClient.addictiveStuffClientArchive
            ),expression:"\n              usersPrivilegeAddictiveStuffClient.addictiveStuffClientArchive\n            "}],staticClass:"checkbox",attrs:{"type":"checkbox","id":"addictiveStuffClientArchive"},domProps:{"checked":Array.isArray(
              _vm.usersPrivilegeAddictiveStuffClient.addictiveStuffClientArchive
            )?_vm._i(
              _vm.usersPrivilegeAddictiveStuffClient.addictiveStuffClientArchive
            ,null)>-1:(
              _vm.usersPrivilegeAddictiveStuffClient.addictiveStuffClientArchive
            )},on:{"change":function($event){var $$a=
              _vm.usersPrivilegeAddictiveStuffClient.addictiveStuffClientArchive
            ,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.usersPrivilegeAddictiveStuffClient, "addictiveStuffClientArchive", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.usersPrivilegeAddictiveStuffClient, "addictiveStuffClientArchive", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.usersPrivilegeAddictiveStuffClient, "addictiveStuffClientArchive", $$c)}}}}),_c('label',{staticClass:"mt-1 ml-2",attrs:{"for":"addictiveStuffClientArchive"}},[_vm._v(_vm._s(_vm.$t("archive")))])]),_c('div',{staticClass:"form-label-group col-md-6 col-lg-4"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
              _vm.usersPrivilegeAddictiveStuffClient.addictiveStuffClientDelete
            ),expression:"\n              usersPrivilegeAddictiveStuffClient.addictiveStuffClientDelete\n            "}],staticClass:"checkbox",attrs:{"type":"checkbox","id":"addictiveStuffClientDelete"},domProps:{"checked":Array.isArray(
              _vm.usersPrivilegeAddictiveStuffClient.addictiveStuffClientDelete
            )?_vm._i(
              _vm.usersPrivilegeAddictiveStuffClient.addictiveStuffClientDelete
            ,null)>-1:(
              _vm.usersPrivilegeAddictiveStuffClient.addictiveStuffClientDelete
            )},on:{"change":function($event){var $$a=
              _vm.usersPrivilegeAddictiveStuffClient.addictiveStuffClientDelete
            ,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.usersPrivilegeAddictiveStuffClient, "addictiveStuffClientDelete", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.usersPrivilegeAddictiveStuffClient, "addictiveStuffClientDelete", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.usersPrivilegeAddictiveStuffClient, "addictiveStuffClientDelete", $$c)}}}}),_c('label',{staticClass:"mt-1 ml-2",attrs:{"for":"addictiveStuffClientDelete"}},[_vm._v(_vm._s(_vm.$t("finalDelete")))])]),_c('div',{staticClass:"form-label-group col-md-6 col-lg-4"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
              _vm.usersPrivilegeAddictiveStuffClient.addictiveStuffClientRestore
            ),expression:"\n              usersPrivilegeAddictiveStuffClient.addictiveStuffClientRestore\n            "}],staticClass:"checkbox",attrs:{"type":"checkbox","id":"addictiveStuffClientRestore"},domProps:{"checked":Array.isArray(
              _vm.usersPrivilegeAddictiveStuffClient.addictiveStuffClientRestore
            )?_vm._i(
              _vm.usersPrivilegeAddictiveStuffClient.addictiveStuffClientRestore
            ,null)>-1:(
              _vm.usersPrivilegeAddictiveStuffClient.addictiveStuffClientRestore
            )},on:{"change":function($event){var $$a=
              _vm.usersPrivilegeAddictiveStuffClient.addictiveStuffClientRestore
            ,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.usersPrivilegeAddictiveStuffClient, "addictiveStuffClientRestore", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.usersPrivilegeAddictiveStuffClient, "addictiveStuffClientRestore", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.usersPrivilegeAddictiveStuffClient, "addictiveStuffClientRestore", $$c)}}}}),_c('label',{staticClass:"mt-1 ml-2",attrs:{"for":"addictiveStuffClientRestore"}},[_vm._v(_vm._s(_vm.$t("restore")))])])])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }