var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{},[_c('vue-cal',{attrs:{"id":"vuecal","events":_vm.events,"selected-date":"2022-06-19","time-from":0 * 60,"time-to":24 * 60,"time-step":30,"hide-weekends":false,"events-count-on-year-view":"","events-on-month-view":true,"dbl-click-to-navigate":false,"hide-view-selector":false,"time-cell-height":_vm.timeCellHeight,"locale":"en","active-view":"month","min-event-width":_vm.minEventWidth,"on-event-click":_vm.echo},scopedSlots:_vm._u([{key:"no-event",fn:function(){return [_vm._v("No event 👌")]},proxy:true},{key:"title",fn:function(ref){
var title = ref.title;
var view = ref.view;
return [_vm._v(" 👌 "+_vm._s(title)+" 👌 "+_vm._s(view.startDate.getFullYear())+"-"+_vm._s(_vm._f("addZero")((view.startDate.getMonth() + 1)))+_vm._s(view.startDate.getMonth() + 1)+" "),(view.id === 'day')?_c('span',[_vm._v("-"+_vm._s(_vm._f("addZero")(view.startDate.getDate()))+_vm._s(view.startDate.getDate()))]):_vm._e()]}},{key:"time-cell",fn:function(ref){
var hours = ref.hours;
var minutes = ref.minutes;
return [_c('div',{class:{ 'vuecal__time-cell-line': true, hours: !minutes }},[(!minutes)?_c('strong',{staticStyle:{"font-size":"15px"}},[_vm._v(_vm._s(_vm._f("addZero")(hours))+_vm._s(hours))]):_c('span',{staticStyle:{"font-size":"11px"}},[_vm._v(_vm._s(_vm._f("addZero")(minutes))+_vm._s(minutes))])])]}},{key:"event",fn:function(ref){
var event = ref.event;
return [_c('div',{staticClass:"vuecal__event-title",domProps:{"innerHTML":_vm._s(event.title)}}),_c('hr'),_c('span',{staticClass:"vuecal__event-time"},[_c('strong',[_vm._v("Event start:")]),_c('span',[_vm._v(_vm._s(event.start.formatTime()))]),_c('br'),_c('strong',[_vm._v("Event end:")]),_c('span',[_vm._v(_vm._s(event.end.formatTime()))])])]}},{key:"arrow-prev",fn:function(){return [_vm._v("◀")]},proxy:true},{key:"arrow-next",fn:function(){return [_vm._v("▶")]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }