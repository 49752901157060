var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{},[_c('b-button',{directives:[{name:"b-toggle",rawName:"v-b-toggle.ServiceReservationMedia",modifiers:{"ServiceReservationMedia":true}}],staticClass:"btn btn-lg btn-collapse"},[_vm._v(" "+_vm._s(_vm.$t("ServiceReservationMedia.modelName"))+" "),_c('i',{staticClass:"fa fa-angle-down",attrs:{"aria-hidden":"true"}})]),_c('b-collapse',{attrs:{"id":"ServiceReservationMedia"}},[_c('div',{staticClass:"my-card"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"form-label-group col-md-6 col-lg-4"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
              _vm.usersPrivilegeServiceReservationMedia.serviceReservationMediaView
            ),expression:"\n              usersPrivilegeServiceReservationMedia.serviceReservationMediaView\n            "}],staticClass:"checkbox",attrs:{"type":"checkbox","id":"serviceReservationMediaView"},domProps:{"checked":Array.isArray(
              _vm.usersPrivilegeServiceReservationMedia.serviceReservationMediaView
            )?_vm._i(
              _vm.usersPrivilegeServiceReservationMedia.serviceReservationMediaView
            ,null)>-1:(
              _vm.usersPrivilegeServiceReservationMedia.serviceReservationMediaView
            )},on:{"change":function($event){var $$a=
              _vm.usersPrivilegeServiceReservationMedia.serviceReservationMediaView
            ,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.usersPrivilegeServiceReservationMedia, "serviceReservationMediaView", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.usersPrivilegeServiceReservationMedia, "serviceReservationMediaView", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.usersPrivilegeServiceReservationMedia, "serviceReservationMediaView", $$c)}}}}),_c('label',{staticClass:"mt-1 ml-2",attrs:{"for":"serviceReservationMediaView"}},[_vm._v(_vm._s(_vm.$t("view")))])]),_c('div',{staticClass:"form-label-group col-md-6 col-lg-4"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
              _vm.usersPrivilegeServiceReservationMedia.serviceReservationMediaAdd
            ),expression:"\n              usersPrivilegeServiceReservationMedia.serviceReservationMediaAdd\n            "}],staticClass:"checkbox",attrs:{"type":"checkbox","id":"serviceReservationMediaAdd"},domProps:{"checked":Array.isArray(
              _vm.usersPrivilegeServiceReservationMedia.serviceReservationMediaAdd
            )?_vm._i(
              _vm.usersPrivilegeServiceReservationMedia.serviceReservationMediaAdd
            ,null)>-1:(
              _vm.usersPrivilegeServiceReservationMedia.serviceReservationMediaAdd
            )},on:{"change":function($event){var $$a=
              _vm.usersPrivilegeServiceReservationMedia.serviceReservationMediaAdd
            ,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.usersPrivilegeServiceReservationMedia, "serviceReservationMediaAdd", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.usersPrivilegeServiceReservationMedia, "serviceReservationMediaAdd", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.usersPrivilegeServiceReservationMedia, "serviceReservationMediaAdd", $$c)}}}}),_c('label',{staticClass:"mt-1 ml-2",attrs:{"for":"serviceReservationMediaAdd"}},[_vm._v(_vm._s(_vm.$t("add")))])]),_c('div',{staticClass:"form-label-group col-md-6 col-lg-4"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
              _vm.usersPrivilegeServiceReservationMedia.serviceReservationMediaUpdate
            ),expression:"\n              usersPrivilegeServiceReservationMedia.serviceReservationMediaUpdate\n            "}],staticClass:"checkbox",attrs:{"type":"checkbox","id":"serviceReservationMediaUpdate"},domProps:{"checked":Array.isArray(
              _vm.usersPrivilegeServiceReservationMedia.serviceReservationMediaUpdate
            )?_vm._i(
              _vm.usersPrivilegeServiceReservationMedia.serviceReservationMediaUpdate
            ,null)>-1:(
              _vm.usersPrivilegeServiceReservationMedia.serviceReservationMediaUpdate
            )},on:{"change":function($event){var $$a=
              _vm.usersPrivilegeServiceReservationMedia.serviceReservationMediaUpdate
            ,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.usersPrivilegeServiceReservationMedia, "serviceReservationMediaUpdate", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.usersPrivilegeServiceReservationMedia, "serviceReservationMediaUpdate", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.usersPrivilegeServiceReservationMedia, "serviceReservationMediaUpdate", $$c)}}}}),_c('label',{staticClass:"mt-1 ml-2",attrs:{"for":"serviceReservationMediaUpdate"}},[_vm._v(_vm._s(_vm.$t("edit")))])]),_c('div',{staticClass:"form-label-group col-md-6 col-lg-4"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
              _vm.usersPrivilegeServiceReservationMedia.serviceReservationMediaArchive
            ),expression:"\n              usersPrivilegeServiceReservationMedia.serviceReservationMediaArchive\n            "}],staticClass:"checkbox",attrs:{"type":"checkbox","id":"serviceReservationMediaArchive"},domProps:{"checked":Array.isArray(
              _vm.usersPrivilegeServiceReservationMedia.serviceReservationMediaArchive
            )?_vm._i(
              _vm.usersPrivilegeServiceReservationMedia.serviceReservationMediaArchive
            ,null)>-1:(
              _vm.usersPrivilegeServiceReservationMedia.serviceReservationMediaArchive
            )},on:{"change":function($event){var $$a=
              _vm.usersPrivilegeServiceReservationMedia.serviceReservationMediaArchive
            ,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.usersPrivilegeServiceReservationMedia, "serviceReservationMediaArchive", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.usersPrivilegeServiceReservationMedia, "serviceReservationMediaArchive", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.usersPrivilegeServiceReservationMedia, "serviceReservationMediaArchive", $$c)}}}}),_c('label',{staticClass:"mt-1 ml-2",attrs:{"for":"serviceReservationMediaArchive"}},[_vm._v(_vm._s(_vm.$t("archive")))])]),_c('div',{staticClass:"form-label-group col-md-6 col-lg-4"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
              _vm.usersPrivilegeServiceReservationMedia.serviceReservationMediaDelete
            ),expression:"\n              usersPrivilegeServiceReservationMedia.serviceReservationMediaDelete\n            "}],staticClass:"checkbox",attrs:{"type":"checkbox","id":"serviceReservationMediaDelete"},domProps:{"checked":Array.isArray(
              _vm.usersPrivilegeServiceReservationMedia.serviceReservationMediaDelete
            )?_vm._i(
              _vm.usersPrivilegeServiceReservationMedia.serviceReservationMediaDelete
            ,null)>-1:(
              _vm.usersPrivilegeServiceReservationMedia.serviceReservationMediaDelete
            )},on:{"change":function($event){var $$a=
              _vm.usersPrivilegeServiceReservationMedia.serviceReservationMediaDelete
            ,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.usersPrivilegeServiceReservationMedia, "serviceReservationMediaDelete", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.usersPrivilegeServiceReservationMedia, "serviceReservationMediaDelete", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.usersPrivilegeServiceReservationMedia, "serviceReservationMediaDelete", $$c)}}}}),_c('label',{staticClass:"mt-1 ml-2",attrs:{"for":"serviceReservationMediaDelete"}},[_vm._v(_vm._s(_vm.$t("finalDelete")))])]),_c('div',{staticClass:"form-label-group col-md-6 col-lg-4"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
              _vm.usersPrivilegeServiceReservationMedia.serviceReservationMediaRestore
            ),expression:"\n              usersPrivilegeServiceReservationMedia.serviceReservationMediaRestore\n            "}],staticClass:"checkbox",attrs:{"type":"checkbox","id":"serviceReservationMediaRestore"},domProps:{"checked":Array.isArray(
              _vm.usersPrivilegeServiceReservationMedia.serviceReservationMediaRestore
            )?_vm._i(
              _vm.usersPrivilegeServiceReservationMedia.serviceReservationMediaRestore
            ,null)>-1:(
              _vm.usersPrivilegeServiceReservationMedia.serviceReservationMediaRestore
            )},on:{"change":function($event){var $$a=
              _vm.usersPrivilegeServiceReservationMedia.serviceReservationMediaRestore
            ,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.usersPrivilegeServiceReservationMedia, "serviceReservationMediaRestore", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.usersPrivilegeServiceReservationMedia, "serviceReservationMediaRestore", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.usersPrivilegeServiceReservationMedia, "serviceReservationMediaRestore", $$c)}}}}),_c('label',{staticClass:"mt-1 ml-2",attrs:{"for":"serviceReservationMediaRestore"}},[_vm._v(_vm._s(_vm.$t("restore")))])])])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }