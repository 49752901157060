var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{},[(_vm.isLoading)?_c('PreLoader'):_vm._e(),(_vm.exceptionMsg)?_c('ExceptionWithImg',{attrs:{"msg":_vm.exceptionMsg}}):_vm._e(),(!_vm.isLoading)?_c('div',[(
        _vm.checkPrivilege(
          _vm.usersPrivilegeData.usersPrivilegeStatistics
            .servicesReservationStatisticsView
        )
      )?_c('YearsStatisticsComponent',{attrs:{"yearsData":_vm.statistics.allStatisticsData.yearsData}}):_vm._e(),(
        _vm.checkPrivilege(
          _vm.usersPrivilegeData.usersPrivilegeStatistics.generalStatisticsView
        )
      )?_c('GeneralStatisticsComponent',{attrs:{"generalStatisticsData":_vm.statistics.allStatisticsData.generalStatisticsData}}):_vm._e(),(
        _vm.checkPrivilege(
          _vm.usersPrivilegeData.usersPrivilegeStatistics
            .branchesInformationStatisticsView
        )
      )?_c('BranchesStatisticsComponent',{attrs:{"branchesInformationStatisticsData":_vm.statistics.allStatisticsData.branchesInformationStatisticsData}}):_vm._e(),(
        _vm.checkPrivilege(
          _vm.usersPrivilegeData.usersPrivilegeStatistics
            .additionalSettingsStatisticsView
        )
      )?_c('AdditionalSettingsStatisticsComponent',{attrs:{"additionalSettingsStatisticsData":_vm.statistics.allStatisticsData.additionalSettingsStatisticsData}}):_vm._e()],1):_vm._e(),(!_vm.isLoading)?_c('ServicesReservationFloatBtns',{attrs:{"filterServicesReservationData":_vm.statistics.filterData,"hideAddBtn":true},on:{"search":_vm.refresh}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }