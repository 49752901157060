var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"fab-container"},[_vm._m(0),_c('ul',{staticClass:"fab-options"},[_c('li',[_c('span',{staticClass:"fab-label"},[_c('router-link',{attrs:{"to":{
            name: 'DoctorNoteAdd',
            params: {
              clientToken: _vm.filterDoctorNoteData.clientToken,
            },
          }}},[_vm._v(" "+_vm._s(_vm.$t("historicalPlans.doctorNotes.add"))+" ")])],1),_c('div',{staticClass:"fab-icon-holder"},[_c('router-link',{attrs:{"to":{
            name: 'DoctorNoteAdd',
            params: {
              clientToken: _vm.filterDoctorNoteData.clientToken,
            },
          }}},[_c('img',{attrs:{"src":require("@/assets/images/plus.svg")}})])],1)]),_c('li',[_c('span',{staticClass:"fab-label",on:{"click":_vm.open}},[_vm._v(" "+_vm._s(_vm.$t("filter"))+" ")]),_c('div',{staticClass:"fab-icon-holder",on:{"click":_vm.open}},[_c('img',{attrs:{"src":require("@/assets/images/filter.svg")}})])])]),_c('vue-bottom-sheet',{ref:"CustomBottomSheet"},[_c('div',{staticClass:"row"},[_c('CustomSelectBox',{attrs:{"className":'col-md-12',"id":'clientToken',"value":_vm.filterData.clientToken,"options":_vm.clientTokenOptions,"title":_vm.$t('selectClient'),"imgName":'clients.svg'},on:{"changeValue":function($event){_vm.filterData.clientToken = $event}}}),_c('CustomInput',{attrs:{"className":'col-md-12',"id":'textSearch',"value":_vm.filterData.textSearch,"title":_vm.$t('search'),"imgName":'search.svg'},on:{"changeValue":function($event){_vm.filterData.textSearch = $event}}})],1),_c('div',{staticClass:"form-footer"},[_c('button',{staticClass:"btn btn-submit",attrs:{"name":"submit","type":"submit"},on:{"click":function($event){$event.preventDefault();return _vm.search.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.$t("search"))+" ")]),_c('button',{staticClass:"btn btn-cancel",on:{"click":function($event){$event.preventDefault();return _vm.close.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.$t("cancel"))+" ")])])])],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"fab-parent fab-icon-holder"},[_c('i',{staticClass:"fa fa-chevron-up"})])}]

export { render, staticRenderFns }