import { render, staticRenderFns } from "./TreasuryTransactionReceipt.vue?vue&type=template&id=febf779e&scoped=true&"
import script from "./TreasuryTransactionReceipt.vue?vue&type=script&lang=js&"
export * from "./TreasuryTransactionReceipt.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "febf779e",
  null
  
)

export default component.exports