import { render, staticRenderFns } from "./BodyAnatomyTypes.vue?vue&type=template&id=7edf2446&"
import script from "./BodyAnatomyTypes.vue?vue&type=script&lang=js&"
export * from "./BodyAnatomyTypes.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports