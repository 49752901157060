var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{},[_c('b-button',{directives:[{name:"b-toggle",rawName:"v-b-toggle.statistics",modifiers:{"statistics":true}}],staticClass:"btn btn-lg btn-collapse"},[_vm._v(" "+_vm._s(_vm.$t("reportsAndStatistics.statistics"))+" "),_c('i',{staticClass:"fa fa-angle-down",attrs:{"aria-hidden":"true"}})]),_c('b-collapse',{attrs:{"id":"statistics"}},[_c('div',{staticClass:"my-card"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"form-label-group col-md-6 col-lg-4"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
              _vm.usersPrivilegeStatistics.servicesReservationStatisticsView
            ),expression:"\n              usersPrivilegeStatistics.servicesReservationStatisticsView\n            "}],staticClass:"checkbox",attrs:{"type":"checkbox","id":"servicesReservationStatisticsView"},domProps:{"checked":Array.isArray(
              _vm.usersPrivilegeStatistics.servicesReservationStatisticsView
            )?_vm._i(
              _vm.usersPrivilegeStatistics.servicesReservationStatisticsView
            ,null)>-1:(
              _vm.usersPrivilegeStatistics.servicesReservationStatisticsView
            )},on:{"change":function($event){var $$a=
              _vm.usersPrivilegeStatistics.servicesReservationStatisticsView
            ,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.usersPrivilegeStatistics, "servicesReservationStatisticsView", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.usersPrivilegeStatistics, "servicesReservationStatisticsView", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.usersPrivilegeStatistics, "servicesReservationStatisticsView", $$c)}}}}),_c('label',{staticClass:"mt-1 ml-2",attrs:{"for":"servicesReservationStatisticsView"}},[_vm._v(_vm._s(_vm.$t("reportsAndStatistics.reservationsStatisticsView")))])]),_c('div',{staticClass:"form-label-group col-md-6 col-lg-4"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.usersPrivilegeStatistics.generalStatisticsView),expression:"usersPrivilegeStatistics.generalStatisticsView"}],staticClass:"checkbox",attrs:{"type":"checkbox","id":"generalStatisticsView"},domProps:{"checked":Array.isArray(_vm.usersPrivilegeStatistics.generalStatisticsView)?_vm._i(_vm.usersPrivilegeStatistics.generalStatisticsView,null)>-1:(_vm.usersPrivilegeStatistics.generalStatisticsView)},on:{"change":function($event){var $$a=_vm.usersPrivilegeStatistics.generalStatisticsView,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.usersPrivilegeStatistics, "generalStatisticsView", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.usersPrivilegeStatistics, "generalStatisticsView", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.usersPrivilegeStatistics, "generalStatisticsView", $$c)}}}}),_c('label',{staticClass:"mt-1 ml-2",attrs:{"for":"generalStatisticsView"}},[_vm._v(_vm._s(_vm.$t("reportsAndStatistics.generalStatisticsView")))])]),_c('div',{staticClass:"form-label-group col-md-6 col-lg-4"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
              _vm.usersPrivilegeStatistics.branchesInformationStatisticsView
            ),expression:"\n              usersPrivilegeStatistics.branchesInformationStatisticsView\n            "}],staticClass:"checkbox",attrs:{"type":"checkbox","id":"branchesInformationStatisticsView"},domProps:{"checked":Array.isArray(
              _vm.usersPrivilegeStatistics.branchesInformationStatisticsView
            )?_vm._i(
              _vm.usersPrivilegeStatistics.branchesInformationStatisticsView
            ,null)>-1:(
              _vm.usersPrivilegeStatistics.branchesInformationStatisticsView
            )},on:{"change":function($event){var $$a=
              _vm.usersPrivilegeStatistics.branchesInformationStatisticsView
            ,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.usersPrivilegeStatistics, "branchesInformationStatisticsView", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.usersPrivilegeStatistics, "branchesInformationStatisticsView", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.usersPrivilegeStatistics, "branchesInformationStatisticsView", $$c)}}}}),_c('label',{staticClass:"mt-1 ml-2",attrs:{"for":"branchesInformationStatisticsView"}},[_vm._v(_vm._s(_vm.$t("reportsAndStatistics.branchesInformationView")))])]),_c('div',{staticClass:"form-label-group col-md-6 col-lg-4"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
              _vm.usersPrivilegeStatistics.additionalSettingsStatisticsView
            ),expression:"\n              usersPrivilegeStatistics.additionalSettingsStatisticsView\n            "}],staticClass:"checkbox",attrs:{"type":"checkbox","id":"additionalSettingsStatisticsView"},domProps:{"checked":Array.isArray(
              _vm.usersPrivilegeStatistics.additionalSettingsStatisticsView
            )?_vm._i(
              _vm.usersPrivilegeStatistics.additionalSettingsStatisticsView
            ,null)>-1:(
              _vm.usersPrivilegeStatistics.additionalSettingsStatisticsView
            )},on:{"change":function($event){var $$a=
              _vm.usersPrivilegeStatistics.additionalSettingsStatisticsView
            ,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.usersPrivilegeStatistics, "additionalSettingsStatisticsView", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.usersPrivilegeStatistics, "additionalSettingsStatisticsView", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.usersPrivilegeStatistics, "additionalSettingsStatisticsView", $$c)}}}}),_c('label',{staticClass:"mt-1 ml-2",attrs:{"for":"additionalSettingsStatisticsView"}},[_vm._v(_vm._s(_vm.$t("reportsAndStatistics.additionalSettingsView")))])])])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }