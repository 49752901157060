var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"id":"ApproveOnServicesReservation","scrollable":"","size":"xl","centered":"","hide-footer":""},on:{"show":function($event){return _vm.getClientDetails()}},scopedSlots:_vm._u([{key:"modal-title",fn:function(){return [_c('h3',[_c('img',{staticClass:"icon-lg",attrs:{"src":require("@/assets/images/approve-reservation.svg")}}),_vm._v(" "+_vm._s(_vm.$t("approveOnServicesReservation"))+" ")])]},proxy:true}])},[(_vm.isLoading)?_c('PreLoader'):_vm._e(),(_vm.exceptionMsg)?_c('ExceptionWithImg',{attrs:{"msg":_vm.exceptionMsg}}):_vm._e(),_c('form',{attrs:{"autocomplete":"off","method":"post"}},[_c('div',{staticClass:"row"},[_c('TextArea',{attrs:{"className":'col-md-12',"id":'approverNote',"value":_vm.servicesReservationData.approverNote,"title":_vm.$t('notes'),"imgName":'notes.svg'},on:{"changeValue":function($event){_vm.servicesReservationData.approverNote = $event}}})],1),_c('div',[_c('b-button',{directives:[{name:"b-toggle",rawName:"v-b-toggle.paymentData",modifiers:{"paymentData":true}}],staticClass:"btn btn-lg btn-collapse"},[_vm._v(" "+_vm._s(_vm.$t("paymentData"))+" "),_c('i',{staticClass:"fa fa-angle-down",attrs:{"aria-hidden":"true"}})]),_c('b-collapse',{attrs:{"visible":"","id":"paymentData"}},[_c('div',{staticClass:"my-card"},[_c('div',{staticClass:"row"},[_c('CustomSelectBox',{attrs:{"className":'col-md-4',"id":'treasuryToken',"value":_vm.servicesReservationData.servicesReservationPayData
                  .treasuryToken,"options":_vm.treasuryTokenOptions,"title":_vm.$t('selectTreasury'),"imgName":'treasuries.svg'},on:{"changeValue":function($event){_vm.servicesReservationData.servicesReservationPayData.treasuryToken =
                  $event}}}),_c('CustomSelectBox',{attrs:{"className":'col-md-4',"id":'methodPaidToken',"value":_vm.servicesReservationData.servicesReservationPayData
                  .methodPaidToken,"options":_vm.methodPaidTokenOptions,"title":_vm.$t('selectMethodPaid'),"imgName":'payment-method.svg'},on:{"changeValue":function($event){_vm.servicesReservationData.servicesReservationPayData.methodPaidToken =
                  $event}}}),_c('CustomInputFloat',{attrs:{"className":'col-md-4',"id":'methodPaidValue',"value":_vm.servicesReservationData.servicesReservationPayData
                  .methodPaidValue,"title":_vm.$t('paidMoney'),"imgName":'money.svg'},on:{"changeValue":function($event){_vm.servicesReservationData.servicesReservationPayData.methodPaidValue =
                  $event}}}),_c('hr',{staticClass:"form-divider"}),_c('CustomSelectBox',{attrs:{"className":'col-md-6',"id":'feesTypeToken',"value":_vm.servicesReservationData.feesTypeToken,"options":_vm.feesTypeTokenOptions,"title":_vm.$t('selectFeesType'),"imgName":'type.svg'},on:{"changeValue":function($event){_vm.servicesReservationData.feesTypeToken = $event}}}),_c('CustomInputFloat',{attrs:{"className":'col-md-6',"id":'feesTypeValue',"value":_vm.servicesReservationData.feesTypeValue,"title":_vm.$t('feesValue'),"imgName":'money.svg'},on:{"changeValue":function($event){_vm.servicesReservationData.feesTypeValue = $event}}}),_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"row"},[_c('CustomCheckbox',{attrs:{"className":'col-md-2',"value":_vm.servicesReservationData.calculateTaxesStatus,"title":_vm.$t('calculateTaxesStatus')},on:{"changeValue":function($event){_vm.servicesReservationData.calculateTaxesStatus = $event}}}),_c('div',{staticClass:"col-md-10"},[(_vm.servicesReservationData.calculateTaxesStatus)?[_c('div',{staticClass:"row"},[_c('CustomInputFloat',{attrs:{"className":'col-md-4',"id":'serviceTaxValue',"value":_vm.servicesReservationData.serviceTaxValue,"title":_vm.$t('value'),"imgName":'percentage.svg'},on:{"changeValue":function($event){_vm.servicesReservationData.serviceTaxValue = $event}}}),_c('CustomCheckbox',{attrs:{"className":'col-md-4',"value":_vm.servicesReservationData.addTaxForOnlyServicePriceStatus,"title":_vm.$t('addTaxForOnlyService')},on:{"changeValue":function($event){_vm.servicesReservationData.addTaxForOnlyServicePriceStatus =
                            $event}}}),_c('CustomCheckbox',{attrs:{"className":'col-md-4',"value":!_vm.servicesReservationData.addTaxForOnlyServicePriceStatus,"title":_vm.$t('addTaxForEffects')},on:{"changeValue":function($event){_vm.servicesReservationData.addTaxForOnlyServicePriceStatus =
                            !$event}}})],1)]:_vm._e()],2)],1)]),_c('DataLabelGroup',{attrs:{"className":'col-md-3',"value":_vm.theClientData.clientTotalTransactionPoints,"title":_vm.$t('pointsAndWallets.points.balance'),"imgName":'points.svg'}}),_c('CustomInputFloat',{attrs:{"className":'col-md-3',"id":'pointsValue',"value":_vm.servicesReservationData.servicesReservationPayData.pointsValue,"title":_vm.$t('pointsAndWallets.points.number'),"imgName":'number.svg'},on:{"changeValue":function($event){_vm.servicesReservationData.servicesReservationPayData.pointsValue =
                  $event}}}),_c('CustomInputFloat',{attrs:{"className":'col-md-3',"id":'onePointEqualValue',"value":_vm.servicesReservationData.servicesReservationPayData
                  .onePointEqualValue,"title":_vm.$t('pointsAndWallets.points.paid'),"imgName":'points.svg'},on:{"changeValue":function($event){_vm.servicesReservationData.servicesReservationPayData.onePointEqualValue =
                  $event}}}),_c('DataLabelGroup',{attrs:{"className":'col-md-3',"title":_vm.$t('pointsAndWallets.points.totalPaid'),"imgName":'money.svg'},model:{value:(_vm.totalPointsPaid),callback:function ($$v) {_vm.totalPointsPaid=$$v},expression:"totalPointsPaid"}}),_c('DataLabelGroup',{attrs:{"className":'col-md-6',"value":_vm.moneyFormat(_vm.theClientData.clientWalletBalance),"title":_vm.$t('pointsAndWallets.wallets.balance'),"imgName":'wallet.svg'}}),_c('CustomInputFloat',{attrs:{"className":'col-md-6',"id":'walletValue',"value":_vm.servicesReservationData.servicesReservationPayData.walletValue,"title":_vm.$t('pointsAndWallets.wallets.paidMoney'),"imgName":'wallet.svg'},on:{"changeValue":function($event){_vm.servicesReservationData.servicesReservationPayData.walletValue =
                  $event}}}),_c('TextArea',{attrs:{"className":'col-md-12',"id":'payNote',"value":_vm.servicesReservationData.servicesReservationPayData.payNote,"title":_vm.$t('notes'),"imgName":'notes.svg'},on:{"changeValue":function($event){_vm.servicesReservationData.servicesReservationPayData.payNote =
                  $event}}})],1)])])],1),_c('div',{staticClass:"form-footer"},[_c('button',{staticClass:"btn btn-submit-2",attrs:{"name":"enquiry","disabled":!_vm.enquiryStatus},on:{"click":function($event){$event.preventDefault();return _vm.openBottomSheet('ServicesReservationFormEnquiry')}}},[_vm._v(" "+_vm._s(_vm.$t("ServiceFinalPrice.enquiry"))+" ")]),_c('button',{staticClass:"btn btn-submit",attrs:{"name":"submit","type":"submit"},on:{"click":function($event){$event.preventDefault();return _vm.approveOnServicesReservation.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.$t("ok"))+" ")]),_c('button',{staticClass:"btn btn-cancel",attrs:{"name":"submit","type":"submit"},on:{"click":function($event){$event.preventDefault();return _vm.$bvModal.hide('ApproveOnServicesReservation')}}},[_vm._v(" "+_vm._s(_vm.$t("cancel"))+" ")])])]),_c('ServicesReservationFormEnquiry',{attrs:{"servicesReservationData":_vm.servicesReservationData}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }