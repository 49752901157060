var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{},[_c('b-button',{directives:[{name:"b-toggle",rawName:"v-b-toggle.ServicesReservationAddedServices",modifiers:{"ServicesReservationAddedServices":true}}],staticClass:"btn btn-lg btn-collapse"},[_vm._v(" "+_vm._s(_vm.$t("ServicesReservationAddedServices.modelName"))+" "),_c('i',{staticClass:"fa fa-angle-down",attrs:{"aria-hidden":"true"}})]),_c('b-collapse',{attrs:{"id":"ServicesReservationAddedServices"}},[_c('div',{staticClass:"my-card"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"form-label-group col-md-6 col-lg-4"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
              _vm.usersPrivilegeServicesReservationAddedServices.servicesReservationAddedServicesView
            ),expression:"\n              usersPrivilegeServicesReservationAddedServices.servicesReservationAddedServicesView\n            "}],staticClass:"checkbox",attrs:{"type":"checkbox","id":"servicesReservationAddedServicesView"},domProps:{"checked":Array.isArray(
              _vm.usersPrivilegeServicesReservationAddedServices.servicesReservationAddedServicesView
            )?_vm._i(
              _vm.usersPrivilegeServicesReservationAddedServices.servicesReservationAddedServicesView
            ,null)>-1:(
              _vm.usersPrivilegeServicesReservationAddedServices.servicesReservationAddedServicesView
            )},on:{"change":function($event){var $$a=
              _vm.usersPrivilegeServicesReservationAddedServices.servicesReservationAddedServicesView
            ,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.usersPrivilegeServicesReservationAddedServices, "servicesReservationAddedServicesView", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.usersPrivilegeServicesReservationAddedServices, "servicesReservationAddedServicesView", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.usersPrivilegeServicesReservationAddedServices, "servicesReservationAddedServicesView", $$c)}}}}),_c('label',{staticClass:"mt-1 ml-2",attrs:{"for":"servicesReservationAddedServicesView"}},[_vm._v(_vm._s(_vm.$t("view")))])]),_c('div',{staticClass:"form-label-group col-md-6 col-lg-4"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
              _vm.usersPrivilegeServicesReservationAddedServices.servicesReservationAddedServicesAdd
            ),expression:"\n              usersPrivilegeServicesReservationAddedServices.servicesReservationAddedServicesAdd\n            "}],staticClass:"checkbox",attrs:{"type":"checkbox","id":"servicesReservationAddedServicesAdd"},domProps:{"checked":Array.isArray(
              _vm.usersPrivilegeServicesReservationAddedServices.servicesReservationAddedServicesAdd
            )?_vm._i(
              _vm.usersPrivilegeServicesReservationAddedServices.servicesReservationAddedServicesAdd
            ,null)>-1:(
              _vm.usersPrivilegeServicesReservationAddedServices.servicesReservationAddedServicesAdd
            )},on:{"change":function($event){var $$a=
              _vm.usersPrivilegeServicesReservationAddedServices.servicesReservationAddedServicesAdd
            ,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.usersPrivilegeServicesReservationAddedServices, "servicesReservationAddedServicesAdd", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.usersPrivilegeServicesReservationAddedServices, "servicesReservationAddedServicesAdd", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.usersPrivilegeServicesReservationAddedServices, "servicesReservationAddedServicesAdd", $$c)}}}}),_c('label',{staticClass:"mt-1 ml-2",attrs:{"for":"servicesReservationAddedServicesAdd"}},[_vm._v(_vm._s(_vm.$t("add")))])]),_c('div',{staticClass:"form-label-group col-md-6 col-lg-4"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
              _vm.usersPrivilegeServicesReservationAddedServices.servicesReservationAddedServicesCancel
            ),expression:"\n              usersPrivilegeServicesReservationAddedServices.servicesReservationAddedServicesCancel\n            "}],staticClass:"checkbox",attrs:{"type":"checkbox","id":"servicesReservationAddedServicesCancel"},domProps:{"checked":Array.isArray(
              _vm.usersPrivilegeServicesReservationAddedServices.servicesReservationAddedServicesCancel
            )?_vm._i(
              _vm.usersPrivilegeServicesReservationAddedServices.servicesReservationAddedServicesCancel
            ,null)>-1:(
              _vm.usersPrivilegeServicesReservationAddedServices.servicesReservationAddedServicesCancel
            )},on:{"change":function($event){var $$a=
              _vm.usersPrivilegeServicesReservationAddedServices.servicesReservationAddedServicesCancel
            ,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.usersPrivilegeServicesReservationAddedServices, "servicesReservationAddedServicesCancel", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.usersPrivilegeServicesReservationAddedServices, "servicesReservationAddedServicesCancel", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.usersPrivilegeServicesReservationAddedServices, "servicesReservationAddedServicesCancel", $$c)}}}}),_c('label',{staticClass:"mt-1 ml-2",attrs:{"for":"servicesReservationAddedServicesCancel"}},[_vm._v(_vm._s(_vm.$t("cancel")))])])])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }