import { render, staticRenderFns } from "./RequirementEdit.vue?vue&type=template&id=b6715934&scoped=true&"
import script from "./RequirementEdit.vue?vue&type=script&lang=js&"
export * from "./RequirementEdit.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b6715934",
  null
  
)

export default component.exports