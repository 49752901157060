var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.medicalRequirementsPlansData)?_c('div',{},_vm._l((_vm.medicalRequirementsPlansData),function(medicalRequirement,index){return _c('div',{key:index,staticClass:"container-collapse-with-btns"},[_c('b-button',{directives:[{name:"b-toggle",rawName:"v-b-toggle",value:(("item" + index)),expression:"`item${index}`"}],staticClass:"btn btn-lg btn-collapse collapse-data"},[_c('div',{staticClass:"p-t-container"},[_c('div',{staticClass:"row collapse-title"},[_c('div',{staticClass:"col-md-12 col-lg-12"},[_vm._v(" "+_vm._s(((_vm.$t("name")) + ": " + (medicalRequirement.mainReservationData.clientNameCurrent) + " " + (_vm.$t("quma")) + " "))+" ")]),_c('div',{staticClass:"col-md-12 col-lg-12"},[_vm._v(" "+_vm._s(((_vm.$t("reservationCode")) + ": " + (medicalRequirement.mainReservationData.reservationCode) + " " + (_vm.$t("quma")) + " "))+" "+_vm._s(((_vm.$t("onDate")) + ": " + (medicalRequirement.mainReservationData.reservationDate ? medicalRequirement.mainReservationData.reservationDate : _vm.$t("notFound")) + " " + (_vm.$t("quma")) + " "))+" "+_vm._s(((_vm.$t("specialSpecialty")) + ": " + (medicalRequirement.mainReservationData .specialSpecialtyNameCurrent) + " " + (_vm.$t("quma")) + " "))+" "+_vm._s(((medicalRequirement.employeeJobNameCurrentInReport ? medicalRequirement.employeeJobNameCurrentInReport : _vm.$t("responsibleForProvidingTheService")) + ": " + (medicalRequirement.employeeNameCurrentInReport)))+" ")])]),_c('div',{staticClass:"collapse-icon"},[_c('i',{staticClass:"fa fa-angle-down",attrs:{"aria-hidden":"true"}})])])]),_c('div',{staticClass:"collapse-actions btn-collapse"},[_c('div',{},[(
            medicalRequirement.requirementTypeToken ==
            _vm.requirementsTypeTokens.Rays
          )?_c('router-link',{attrs:{"to":{
            name: 'MedicalRequirementsPlanRaysEdit',
            params: {
              clientToken: medicalRequirement.clientToken,
              reservationToken: medicalRequirement.reservationToken,
              medicalRequirementsPlanToken:
                medicalRequirement.medicalRequirementsPlanToken,
            },
          },"title":_vm.$t('edit')}},[_c('img',{staticClass:"icon-lg",attrs:{"src":require("@/assets/images/pencil.svg")}})]):(
            medicalRequirement.requirementTypeToken ==
            _vm.requirementsTypeTokens.MedicalTests
          )?_c('router-link',{attrs:{"to":{
            name: 'MedicalRequirementsPlanTestsEdit',
            params: {
              clientToken: medicalRequirement.clientToken,
              reservationToken: medicalRequirement.reservationToken,
              medicalRequirementsPlanToken:
                medicalRequirement.medicalRequirementsPlanToken,
            },
          },"title":_vm.$t('edit')}},[_c('img',{staticClass:"icon-lg",attrs:{"src":require("@/assets/images/pencil.svg")}})]):_vm._e()],1),_c('div',{},[_c('button',{directives:[{name:"b-modal",rawName:"v-b-modal.MedicalRequirementsPlanDelete",modifiers:{"MedicalRequirementsPlanDelete":true}}],staticClass:"btn p-0",attrs:{"title":_vm.$t('delete')},on:{"click":function($event){return _vm.setMedicalRequirementsPlanData(medicalRequirement)}}},[_c('img',{staticClass:"icon-lg",attrs:{"src":require("@/assets/images/trash.svg")}})])]),_c('div',{},[_c('button',{directives:[{name:"b-modal",rawName:"v-b-modal.MedicalRequirementsPlanDownload",modifiers:{"MedicalRequirementsPlanDownload":true}}],staticClass:"btn p-0",attrs:{"title":_vm.$t('viewAndSend')},on:{"click":function($event){return _vm.setMedicalRequirementsPlanData(medicalRequirement)}}},[_c('img',{staticClass:"icon-lg",attrs:{"src":require("@/assets/images/pdf.svg")}})])])]),_c('b-collapse',{attrs:{"id":("item" + index)}},[_c('div',{staticClass:"my-card"},[(
            medicalRequirement.requirementTypeToken ==
            _vm.requirementsTypeTokens.Rays
          )?_c('span',{staticClass:"my-card-title"},[_vm._v(" "+_vm._s(_vm.$t("medicalRequirements.rays.modelName")))]):(
            medicalRequirement.requirementTypeToken ==
            _vm.requirementsTypeTokens.MedicalTests
          )?_c('span',{staticClass:"my-card-title"},[_vm._v(" "+_vm._s(_vm.$t("medicalRequirements.medicalTests.modelName")))]):_vm._e(),_c('div',{staticClass:"table-container"},[_c('table',{staticClass:"my-table"},[_c('thead',[_c('tr',[_c('th',[_vm._v("#")]),_c('th',[_vm._v(_vm._s(_vm.$t("name")))]),_c('th',[_vm._v(_vm._s(_vm.$t("notes")))])])]),_c('tbody',_vm._l((medicalRequirement.medicalRequirementsPlansDetailsData),function(medicalRequirementsPlan,indexMed){return _c('tr',{key:indexMed},[_c('td',[_vm._v(_vm._s(++indexMed))]),_c('td',[_vm._v(" "+_vm._s(_vm.isDataExist( medicalRequirementsPlan.requirementNameCurrent ))+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.isDataExist( medicalRequirementsPlan.medicalRequirementPlanDetailsNotes ))+" ")])])}),0)])])])])],1)}),0):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }