var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{},[_c('b-button',{directives:[{name:"b-toggle",rawName:"v-b-toggle.measurementRooms",modifiers:{"measurementRooms":true}}],staticClass:"btn btn-lg btn-collapse"},[_vm._v(" "+_vm._s(_vm.$t("historicalPlans.measurementRooms.modelName"))+" "),_c('i',{staticClass:"fa fa-angle-down",attrs:{"aria-hidden":"true"}})]),_c('b-collapse',{attrs:{"id":"measurementRooms"}},[_c('div',{staticClass:"my-card"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"form-label-group col-md-6 col-lg-4"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
              _vm.usersPrivilegeMeasurements.servicesReservationViewMeasurementRoom
            ),expression:"\n              usersPrivilegeMeasurements.servicesReservationViewMeasurementRoom\n            "}],staticClass:"checkbox",attrs:{"type":"checkbox","id":"servicesReservationViewMeasurementRoom"},domProps:{"checked":Array.isArray(
              _vm.usersPrivilegeMeasurements.servicesReservationViewMeasurementRoom
            )?_vm._i(
              _vm.usersPrivilegeMeasurements.servicesReservationViewMeasurementRoom
            ,null)>-1:(
              _vm.usersPrivilegeMeasurements.servicesReservationViewMeasurementRoom
            )},on:{"change":function($event){var $$a=
              _vm.usersPrivilegeMeasurements.servicesReservationViewMeasurementRoom
            ,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.usersPrivilegeMeasurements, "servicesReservationViewMeasurementRoom", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.usersPrivilegeMeasurements, "servicesReservationViewMeasurementRoom", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.usersPrivilegeMeasurements, "servicesReservationViewMeasurementRoom", $$c)}}}}),_c('label',{staticClass:"mt-1 ml-2",attrs:{"for":"servicesReservationViewMeasurementRoom"}},[_vm._v(_vm._s(_vm.$t("view")))])]),_c('div',{staticClass:"form-label-group col-md-6 col-lg-4"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
              _vm.usersPrivilegeMeasurements.servicesReservationAddMeasurementRoom
            ),expression:"\n              usersPrivilegeMeasurements.servicesReservationAddMeasurementRoom\n            "}],staticClass:"checkbox",attrs:{"type":"checkbox","id":"servicesReservationAddMeasurementRoom"},domProps:{"checked":Array.isArray(
              _vm.usersPrivilegeMeasurements.servicesReservationAddMeasurementRoom
            )?_vm._i(
              _vm.usersPrivilegeMeasurements.servicesReservationAddMeasurementRoom
            ,null)>-1:(
              _vm.usersPrivilegeMeasurements.servicesReservationAddMeasurementRoom
            )},on:{"change":function($event){var $$a=
              _vm.usersPrivilegeMeasurements.servicesReservationAddMeasurementRoom
            ,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.usersPrivilegeMeasurements, "servicesReservationAddMeasurementRoom", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.usersPrivilegeMeasurements, "servicesReservationAddMeasurementRoom", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.usersPrivilegeMeasurements, "servicesReservationAddMeasurementRoom", $$c)}}}}),_c('label',{staticClass:"mt-1 ml-2",attrs:{"for":"servicesReservationAddMeasurementRoom"}},[_vm._v(_vm._s(_vm.$t("add")))])]),_c('div',{staticClass:"form-label-group col-md-6 col-lg-4"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
              _vm.usersPrivilegeMeasurements.servicesReservationUpdateMeasurementRoom
            ),expression:"\n              usersPrivilegeMeasurements.servicesReservationUpdateMeasurementRoom\n            "}],staticClass:"checkbox",attrs:{"type":"checkbox","id":"servicesReservationUpdateMeasurementRoom"},domProps:{"checked":Array.isArray(
              _vm.usersPrivilegeMeasurements.servicesReservationUpdateMeasurementRoom
            )?_vm._i(
              _vm.usersPrivilegeMeasurements.servicesReservationUpdateMeasurementRoom
            ,null)>-1:(
              _vm.usersPrivilegeMeasurements.servicesReservationUpdateMeasurementRoom
            )},on:{"change":function($event){var $$a=
              _vm.usersPrivilegeMeasurements.servicesReservationUpdateMeasurementRoom
            ,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.usersPrivilegeMeasurements, "servicesReservationUpdateMeasurementRoom", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.usersPrivilegeMeasurements, "servicesReservationUpdateMeasurementRoom", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.usersPrivilegeMeasurements, "servicesReservationUpdateMeasurementRoom", $$c)}}}}),_c('label',{staticClass:"mt-1 ml-2",attrs:{"for":"servicesReservationUpdateMeasurementRoom"}},[_vm._v(_vm._s(_vm.$t("edit")))])]),_c('div',{staticClass:"form-label-group col-md-6 col-lg-4"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
              _vm.usersPrivilegeMeasurements.servicesReservationDeleteMeasurementRoom
            ),expression:"\n              usersPrivilegeMeasurements.servicesReservationDeleteMeasurementRoom\n            "}],staticClass:"checkbox",attrs:{"type":"checkbox","id":"servicesReservationDeleteMeasurementRoom"},domProps:{"checked":Array.isArray(
              _vm.usersPrivilegeMeasurements.servicesReservationDeleteMeasurementRoom
            )?_vm._i(
              _vm.usersPrivilegeMeasurements.servicesReservationDeleteMeasurementRoom
            ,null)>-1:(
              _vm.usersPrivilegeMeasurements.servicesReservationDeleteMeasurementRoom
            )},on:{"change":function($event){var $$a=
              _vm.usersPrivilegeMeasurements.servicesReservationDeleteMeasurementRoom
            ,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.usersPrivilegeMeasurements, "servicesReservationDeleteMeasurementRoom", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.usersPrivilegeMeasurements, "servicesReservationDeleteMeasurementRoom", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.usersPrivilegeMeasurements, "servicesReservationDeleteMeasurementRoom", $$c)}}}}),_c('label',{staticClass:"mt-1 ml-2",attrs:{"for":"servicesReservationDeleteMeasurementRoom"}},[_vm._v(_vm._s(_vm.$t("finalDelete")))])])])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }