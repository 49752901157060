import { render, staticRenderFns } from "./MealsTypeAdd.vue?vue&type=template&id=a642d8c6&scoped=true&"
import script from "./MealsTypeAdd.vue?vue&type=script&lang=js&"
export * from "./MealsTypeAdd.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a642d8c6",
  null
  
)

export default component.exports