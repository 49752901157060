import { render, staticRenderFns } from "./Privileges.vue?vue&type=template&id=b2f1f6c4&scoped=true&"
import script from "./Privileges.vue?vue&type=script&lang=js&"
export * from "./Privileges.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b2f1f6c4",
  null
  
)

export default component.exports