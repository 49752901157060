var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{},[_c('b-button',{directives:[{name:"b-toggle",rawName:"v-b-toggle.ServiceResponsibleBonuses",modifiers:{"ServiceResponsibleBonuses":true}}],staticClass:"btn btn-lg btn-collapse"},[_vm._v(" "+_vm._s(_vm.$t("serviceResponsibleBonuses.modelName"))+" "),_c('i',{staticClass:"fa fa-angle-down",attrs:{"aria-hidden":"true"}})]),_c('b-collapse',{attrs:{"id":"ServiceResponsibleBonuses"}},[_c('div',{staticClass:"my-card"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"form-label-group col-md-6 col-lg-4"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
              _vm.usersPrivilegeServiceResponsibleBonuses.serviceResponsibleBonusesView
            ),expression:"\n              usersPrivilegeServiceResponsibleBonuses.serviceResponsibleBonusesView\n            "}],staticClass:"checkbox",attrs:{"type":"checkbox","id":"serviceResponsibleBonusesView"},domProps:{"checked":Array.isArray(
              _vm.usersPrivilegeServiceResponsibleBonuses.serviceResponsibleBonusesView
            )?_vm._i(
              _vm.usersPrivilegeServiceResponsibleBonuses.serviceResponsibleBonusesView
            ,null)>-1:(
              _vm.usersPrivilegeServiceResponsibleBonuses.serviceResponsibleBonusesView
            )},on:{"change":function($event){var $$a=
              _vm.usersPrivilegeServiceResponsibleBonuses.serviceResponsibleBonusesView
            ,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.usersPrivilegeServiceResponsibleBonuses, "serviceResponsibleBonusesView", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.usersPrivilegeServiceResponsibleBonuses, "serviceResponsibleBonusesView", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.usersPrivilegeServiceResponsibleBonuses, "serviceResponsibleBonusesView", $$c)}}}}),_c('label',{staticClass:"mt-1 ml-2",attrs:{"for":"serviceResponsibleBonusesView"}},[_vm._v(_vm._s(_vm.$t("view")))])]),_c('div',{staticClass:"form-label-group col-md-6 col-lg-4"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
              _vm.usersPrivilegeServiceResponsibleBonuses.serviceResponsibleBonusesUpdate
            ),expression:"\n              usersPrivilegeServiceResponsibleBonuses.serviceResponsibleBonusesUpdate\n            "}],staticClass:"checkbox",attrs:{"type":"checkbox","id":"serviceResponsibleBonusesUpdate"},domProps:{"checked":Array.isArray(
              _vm.usersPrivilegeServiceResponsibleBonuses.serviceResponsibleBonusesUpdate
            )?_vm._i(
              _vm.usersPrivilegeServiceResponsibleBonuses.serviceResponsibleBonusesUpdate
            ,null)>-1:(
              _vm.usersPrivilegeServiceResponsibleBonuses.serviceResponsibleBonusesUpdate
            )},on:{"change":function($event){var $$a=
              _vm.usersPrivilegeServiceResponsibleBonuses.serviceResponsibleBonusesUpdate
            ,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.usersPrivilegeServiceResponsibleBonuses, "serviceResponsibleBonusesUpdate", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.usersPrivilegeServiceResponsibleBonuses, "serviceResponsibleBonusesUpdate", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.usersPrivilegeServiceResponsibleBonuses, "serviceResponsibleBonusesUpdate", $$c)}}}}),_c('label',{staticClass:"mt-1 ml-2",attrs:{"for":"serviceResponsibleBonusesUpdate"}},[_vm._v(_vm._s(_vm.$t("edit")))])])])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }