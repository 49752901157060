import { render, staticRenderFns } from "./BuildingAdd.vue?vue&type=template&id=0af62de1&scoped=true&"
import script from "./BuildingAdd.vue?vue&type=script&lang=js&"
export * from "./BuildingAdd.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0af62de1",
  null
  
)

export default component.exports