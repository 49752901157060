var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flip-card col-12 col-sm-6 col-md-4 col-lg-3"},[_c('div',{staticClass:"flip-card-inner"},[_c('div',{staticClass:"flip-card-front"},[_c('img',{attrs:{"src":_vm.imgSrc,"onerror":("this.src='" + _vm.defaultImg + "'"),"alt":"Image"}}),_c('div',{staticClass:"flip-card-title"},[_c('h4',{staticClass:"cut-1line",attrs:{"title":_vm.$t('mealsTypes.name')}},[_vm._v(" "+_vm._s(_vm.mealsTypeData.mealsTypeNameCurrent)+" ")])])]),_c('div',{staticClass:"flip-card-back"},[_c('router-link',{attrs:{"to":{
          name: 'Meals',
          params: {
            mealsTypeToken: _vm.mealsTypeData.mealsTypeToken,
          },
        },"title":_vm.$t('meals.modelName')}},[_c('img',{staticClass:"icon-lg",attrs:{"src":require("@/assets/images/meal.svg")}})]),_c('div',{staticClass:"flip-card-back-icons"},[_c('button',{directives:[{name:"b-modal",rawName:"v-b-modal.MealsTypeInfo",modifiers:{"MealsTypeInfo":true}}],staticClass:"btn p-0",attrs:{"title":_vm.$t('info')},on:{"click":function($event){return _vm.setMealsTypeData(_vm.mealsTypeData)}}},[_c('img',{staticClass:"icon-lg",attrs:{"src":require("@/assets/images/info.svg")}})]),_c('router-link',{attrs:{"to":{
            name: 'MealsTypeEdit',
            params: {
              mealsTypeToken: _vm.mealsTypeData.mealsTypeToken,
            },
          },"title":_vm.$t('edit')}},[_c('img',{staticClass:"icon-lg",attrs:{"src":require("@/assets/images/pencil.svg")}})]),_c('button',{directives:[{name:"b-modal",rawName:"v-b-modal.MealsTypeDelete",modifiers:{"MealsTypeDelete":true}}],staticClass:"btn p-0",attrs:{"title":_vm.$t('delete')},on:{"click":function($event){return _vm.setMealsTypeData(_vm.mealsTypeData)}}},[_c('img',{staticClass:"icon-lg",attrs:{"src":require("@/assets/images/trash.svg")}})])],1),_c('router-link',{attrs:{"to":{
          name: 'Meals',
          params: {
            mealsTypeToken: _vm.mealsTypeData.mealsTypeToken,
          },
        },"title":_vm.$t('meals.modelName')}},[_c('div',{staticClass:"flip-card-title"},[_c('h4',{staticClass:"cut-1line",attrs:{"title":_vm.$t('meals.modelName')}},[_vm._v(" "+_vm._s(_vm.mealsTypeData.mealsTypeNameCurrent)+" ")])])])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }